import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import logo from './logo-brand.png';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState('');
    const navigate = useNavigate();

    // Role-based redirection function
    const handleRoleBasedRedirect = (role) => {
        if (role === 'admin') {
          navigate('/dashboard'); // Redirect to Dashboard for admin
        } else if (role === 'emp') {
          navigate('/home/welcome'); // Redirect to employee-specific page
        } else if (role === 'writer') {
          navigate('/writer/dashboard'); // Redirect to writer-specific page
        } else {
          setLoginStatus('Unauthorized access. Please contact administrator.');
        }
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoginStatus(''); // Reset login status message

        try {
            const response = await fetch('https://api.inindia.cloud/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                sessionStorage.setItem('token', data.token);
                sessionStorage.setItem('role', data.role);
                sessionStorage.setItem('email', data.email);
                sessionStorage.setItem('name', data.name);

                // Redirect based on the user's role
                handleRoleBasedRedirect(data.role);
            } else {
                setLoginStatus('Login failed. Please check your credentials.');
            }
        } catch (error) {
            setLoginStatus('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="login-container">
            <div className="login-content">
                <img src={logo} alt="Logo" className="login-logo" width={300} />
                <p className="tagline">Building the future in India</p>
                <h1 style={{ fontWeight: 'bold', fontSize: '2rem' }}>Sign In</h1>
                <form onSubmit={handleSubmit} className="login-form">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Sign In</button>
                </form>
                {loginStatus && (
                    <p className="login-status">{loginStatus}</p>
                )}
                <div className="footer-text">ININDIA CORPORATION</div>
            </div>
        </div>
    );
}

export default LoginPage;
