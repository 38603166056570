import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './LeadsTable.css'; // Import CSS file
import LeadEditor from './LeadEditor';

const LeadTable = () => {
    const [leads, setLeads] = useState([]);
    const [hoveredLeadId, setHoveredLeadId] = useState(null);

    useEffect(() => {
        const fetchLeads = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await axios.get('https://api.inindia.cloud/api/get-leads', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setLeads(response.data);
            } catch (error) {
                console.error('Error fetching leads:', error.response?.data?.message || error.message);
            }
        };

        fetchLeads();
    }, []);

    return (
        <div className="table-container">
            <h2>Available Leads</h2>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Contact Number</th>
                        <th>Requirement</th>
                        <th>Source</th>
                        <th>Status</th>
                        <th>Client Name</th>
                        <th>Business Name</th>
                        <th>Alternate Number</th>
                        <th>WhatsApp</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Created</th>
                        <th>Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {leads.map((lead) => (
                        <tr key={lead.id}>
                            <td>{lead.id}</td>
                            <td>{lead.contact_number}</td>
                            <td 
                                className="truncate-text"
                                onMouseEnter={() => setHoveredLeadId(lead.id)}
                                onMouseLeave={() => setHoveredLeadId(null)}
                            >
                                {lead.requirement}
                                {hoveredLeadId === lead.id && (
                                    <span className="tooltip-text">{lead.requirement}</span>
                                )}
                            </td>
                            <td>{lead.lead_source}</td>
                            <td>{lead.status}</td>
                            <td>{lead.client_name}</td>
                            <td>{lead.business_name}</td>
                            <td>{lead.alternate_number}</td>
                            <td>{lead.whatsapp_number}</td>
                            <td>{lead.city}</td>
                            <td>{lead.state}</td>
                            <td>{new Date(lead.created_at).toLocaleDateString()}</td>
                            <td>{new Date(lead.updated_at).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default LeadTable;
