import React, { useState, useEffect } from 'react';
import './TrafficList.css'; // Import your custom CSS file

function TrafficList() {
  const [trafficData, setTrafficData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalTrafficCount, setTotalTrafficCount] = useState(0);
  const [topReferrers, setTopReferrers] = useState([]);
  const [interactionDistribution, setInteractionDistribution] = useState([]);

  useEffect(() => {
    const fetchTrafficData = async () => {
      try {
        const token = sessionStorage.getItem('token');

        const response = await fetch('https://api.inindia.cloud/api/get-traffic', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTrafficData(data);
        setLoading(false);
        setTotalTrafficCount(data.length);
        analyzeTrafficData(data);
      } catch (error) {
        console.error('Error fetching traffic data:', error);
      }
    };

    fetchTrafficData();
  }, []);

  const analyzeTrafficData = (data) => {
    const referrerCounts = {};
    data.forEach((traffic) => {
      const referrer = traffic.referrer || 'Direct';
      referrerCounts[referrer] = referrerCounts[referrer] ? referrerCounts[referrer] + 1 : 1;
    });
    const sortedReferrers = Object.keys(referrerCounts).sort((a, b) => referrerCounts[b] - referrerCounts[a]);
    setTopReferrers(sortedReferrers.slice(0, 5));

    const interactionCounts = {};
    data.forEach((traffic) => {
      const interactionNumber = traffic.interaction_number;
      interactionCounts[interactionNumber] = interactionCounts[interactionNumber] ? interactionCounts[interactionNumber] + 1 : 1;
    });
    setInteractionDistribution(Object.entries(interactionCounts));
  };

  if (loading) {
    return <div>Loading...</div>;
  }


  return (
    <div className="container">
      <h2 className="heading">Traffic Dashboard</h2>
      <div className="grid">
        <div className="card">
          <h3 className="card-title">Total Traffic Count</h3>
          <p>{totalTrafficCount}</p>
        </div>
        <div className="card">
          <h3 className="card-title">Top Referrers</h3>
          <ul>
            {topReferrers.map((referrer, index) => (
              <li key={index}>{referrer}</li>
            ))}
          </ul>
        </div>
        <div className="card">
          <h3 className="card-title">Interaction Number Distribution</h3>
          <ul>
            {interactionDistribution.map(([interactionNumber, count], index) => (
              <li key={index}>Interaction Number: {interactionNumber}, Count: {count}</li>
            ))}
          </ul>
        </div>
      </div>
      <h2 className="heading">Traffic Data</h2>
      <div className="grid">
        {trafficData.map((traffic, index) => (
          <div key={index} className="card">
            <h3 className="card-title">ID: {traffic.id}</h3>
            <p>IP Address: {traffic.ip_address}</p>
            <p>User Agent: {traffic.user_agent}</p>
            <p>Referrer: {traffic.referrer}</p>
            <p>Interaction Number: {traffic.interaction_number}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TrafficList;
