import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ArticleEditor.css';


function ArticleEditor({ article, onClose }) {
  const [editedArticle, setEditedArticle] = useState({ ...article });
  const [description, setDescription] = useState(article.description);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    const token = sessionStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await fetch('https://api.inindia.cloud/api/articles/topics', {
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch topics');
      }
      const data = await response.json();
      setTopics(data.topics);
    } catch (error) {
      console.error('Error fetching topics:', error);
      setError('Error fetching topics. Please try again.');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedArticle({ ...editedArticle, [name]: value || '' });
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    try {
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const response = await fetch(`https://api.inindia.cloud/api/articles/${editedArticle.id}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ ...editedArticle, description: description }),
      });

      if (!response.ok) {
        throw new Error('Failed to update article');
      }

      const data = await response.json();
      console.log('Article updated successfully:', data);
      onClose();
    } catch (error) {
      setError('Error updating article. Please try again.');
      console.error('Error updating article:', error);
    } finally {
      setLoading(false);
    }
  };

  const quillModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {}
    }
  };

  return (
    <div className="article-editor-modal">
      <div className="popup-inner">
      <div className="article-editor-content">
        <form onSubmit={handleSave}>
          <label>
            Distribution:
            <select name="distribution" value={editedArticle.distribution} onChange={handleInputChange} required>
              <option value="inindiatech.com">inindiatech.com</option>
            </select>
          </label>
          <label>
            URL:
            <input type="text" name="url" value={editedArticle.url} onChange={handleInputChange} placeholder="Enter URL..." required />
          </label>
          <label>
            Category:
            <select name="cat" value={editedArticle.cat} onChange={handleInputChange} required>
              <option value="">Select Category</option>
              {topics.map(topic => (
                <option key={topic.id} value={topic.id}>{topic.topic}</option>
              ))}
            </select>
          </label>
          <label>
            Page Name:
            <input type="text" name="page_name" value={editedArticle.page_name} onChange={handleInputChange} placeholder="Enter page name..." required />
          </label>
          <label>
            Subject:
            <input type="text" name="subject" value={editedArticle.subject} onChange={handleInputChange} placeholder="Enter subject..." required />
          </label>
          <label>Short Description:</label>
          <textarea
            name="short_desc"
            value={editedArticle.short_desc}
            onChange={handleInputChange}
            placeholder="Enter short description..."
            style={{ width: '500px', fontSize: '14px' }}
            required
          />
          <label>Long Description:</label>
          <textarea
            name="long_desc"
            value={editedArticle.long_desc}
            onChange={handleInputChange}
            placeholder="Enter long description..."
            style={{ width: '500px', fontSize: '14px' }}
            required
          />
          <label>
            Topic Image:
            <input type="text" name="topic_img" value={editedArticle.topic_img} onChange={handleInputChange} placeholder="Enter topic image URL..." required />
          </label>
          <label>
            Keywords:
            <input type="text" name="keywords" value={editedArticle.keywords} onChange={handleInputChange} placeholder="Enter keywords..." required />
          </label>
          <div className="description">
            <ReactQuill
              theme="snow"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Start typing your description here..."
              modules={quillModules}
            />
          </div>
          <div>
            {loading ? <p>Saving...</p> : <button type="submit">Save</button>}
            <button type="button" onClick={onClose}>Cancel</button>
            {error && <p>{error}</p>}
          </div>
        </form>
      </div>
    </div>
    </div>
  );
}

export default ArticleEditor;
