import React, { useState } from 'react';
import axios from 'axios';
import './SearchComponent.css'; // Import CSS file for styling

function SearchComponent() {
    const [searchIpAddress, setSearchIpAddress] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSearch = async () => {
        try {
            setLoading(true);

            const token = sessionStorage.getItem('token');

            const response = await axios.get('https://api.inindia.cloud/api/search', {
                params: { ip_address: searchIpAddress },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            setSearchResults(response.data);
            setError(null);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setError(error.response.data.message);
            } else {
                setError('Error searching. Please try again later.');
            }
            console.error('Error searching:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClear = () => {
        setSearchIpAddress('');
        setSearchResults([]);
        setError(null);
    };

    return (
        <div className="search-container">
            <h2>Search by IP Address</h2>
            <div className="search-input">
                <input
                    type="text"
                    value={searchIpAddress}
                    onChange={(e) => setSearchIpAddress(e.target.value)}
                    placeholder="Enter IP Address"
                />
                <button onClick={handleSearch} disabled={loading}>Search</button>
                <button onClick={handleClear}>Clear</button>
            </div>
            {loading && <div className="loading">Loading...</div>}
            {error && <div className="error">{error}</div>}
            <ul className="search-results">

                {Array.isArray(searchResults) ? (
                    <ul className="search-results">
                        {searchResults.map((result) => (
                            <li key={result.ip_address}>{result.ip_address}</li>
                        ))}
                    </ul>
                ) : (
                    <div>No search results found</div>
                )}

            </ul>
        </div>
    );
}


export default SearchComponent;
