import './TrafficTable.css'; // Import your custom CSS file


import moment from 'moment-timezone';

function TrafficTable({ data }) {
  return (
    <table>
      <div class="card">

        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th>IP Address</th>
            <th>Page</th>

            <th>Country</th>
            <th>City</th>
            {/* <th>Postal Code</th> */}
            <th>Method</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Visited at (IST)</th>
            {/* Add other headers as needed */}
          </tr>
        </thead>
        <tbody>
          {data.map((traffic, index) => (
            <tr key={index}>
              {/* <td>{traffic.id}</td> */}
              <td>
                <span className="referrer" title={`Referrer: ${traffic.referrer}`}>
                  {traffic.ip_address}
                </span>
              </td>

              <td>
                <span className="location" title={`Request Header: ${traffic.request_header}\nResponse Status Code: ${traffic.response_status_code}`}>
                  {traffic.location}
                </span>
              </td>

              <td>
                <span className="country-code" title={traffic.location_data ? traffic.location_data.country_name : ''}>
                  {traffic.location_data ? traffic.location_data.country_code : ''}
                </span>
              </td>

              <td>
                <span className="city" title={traffic.location_data ? traffic.location_data.postal : ''}>
                  {traffic.location_data ? traffic.location_data.city : ''}
                </span>
              </td>

              {/* <td>{traffic.location_data ? traffic.location_data.postal : ''}</td> */}
              <td>{traffic.request_method ? traffic.request_method : ''}</td>
              <td>{traffic.location_data ? traffic.location_data.latitude : ''}</td>
              <td>{traffic.location_data ? traffic.location_data.longitude : ''}</td>
              <td>{moment.tz(traffic.created_at, 'Asia/Kolkata').format('DD/MM/YYYY HH:mm:ss')}</td>
              {/* Add other data cells as needed */}
            </tr>
          ))}
        </tbody>
      </div>
    </table>
  );
}

export default TrafficTable;
